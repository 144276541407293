import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ApiService} from '../../../services/api.service';
import {Lesson} from '../../../models/lesson';
import {Task} from '../../../models/task';
import {StaticRange} from '../../../models/chart/static-range';
import {ChartStorage} from '../../../storages/chart.storage';
import {UserService} from '../../../services/user.service';
import {HandsRange} from '../../../models/hands-range';

@Injectable({
  providedIn: 'root'
})
export class LessonService {

  constructor(private apiService: ApiService, private chartStorage: ChartStorage, private userService: UserService) { }

  getLessonById(id: number, mode = 'none', isAsymmetric?: boolean): Observable<Lesson> {
    return this.apiService.getLessonById(id, mode, isAsymmetric).pipe(
      map((lesson: Lesson) => {
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson;
      })
    );
  }

  getMixedLessonById(id: number, mode = 'none', stackMin = null, stackMax = null, isAsymmetric?: boolean): Observable<Lesson> {
    return this.apiService.getMixedLessonById(id, mode, stackMin, stackMax, isAsymmetric).pipe(
      map((lesson: Lesson) => {
        lesson.isMixed = true;
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson;
      })
    );
  }

  getMixedLessonAsymmetricById(id: number, mode = 'none', stackMin = null, stackMax = null): Observable<Lesson> {
    return this.apiService.getMixedLessonAsymmetricById(id, mode, stackMin, stackMax).pipe(
      map((lesson: Lesson) => {
        lesson.isMixed = true;
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson;
      })
    );
  }

  getDynamicMixedLessonById(ids: string, mode = 'none', stackMin = null, stackMax = null, isAsymmetric?: boolean): Observable<Lesson> {
    return this.apiService.getDynamicMixedLessonById(ids, mode, stackMin, stackMax, isAsymmetric).pipe(
      map((lesson: Lesson) => {
        lesson.isDynamicMixed = true;
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson;
      })
    );
  }

  getTasksForLesson(id: number, mode: string, isAsymmetric?: boolean): Observable<Task[]> {
    return this.getLessonById(id, mode, isAsymmetric).pipe(
      map((lesson: Lesson) => {
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson.tasks;
      })
    );
  }

  getTasksForMixedLesson(id: number, mode: string, stackMin: number, stackMax: number, isAsymmetric?: boolean): Observable<Task[]> {
    return this.getMixedLessonById(id, mode, stackMin, stackMax, isAsymmetric).pipe(
      map((lesson: Lesson) =>  {
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson.tasks;
      })
    );
  }

  getTasksForDynamicMixedLesson(ids: string, mode: string, stackMin: number, stackMax: number, isAsymmetric?: boolean): Observable<Task[]> {
    return this.getDynamicMixedLessonById(ids, mode, stackMin, stackMax, isAsymmetric).pipe(
      map((lesson: Lesson) =>  {
        lesson.tasks.forEach((task: Task) => {
          task.hero.nickname = this.userService.getUser().nickname;
        });
        return lesson.tasks;
      })
    );
  }

  getRangeById(rangeId: number, secondAction: number, secondActionFactor: number, isAsymmetric?: boolean): Observable<StaticRange> {
    return this.chartStorage.getRangeById(rangeId, secondAction, secondActionFactor, isAsymmetric);
  }

  getHandsRanges(): Observable<HandsRange[]> {
    return this.apiService.getHandsRanges();
  }

  saveHandsRange(range: HandsRange): Observable<any> {
    return this.apiService.saveHandsRange(range);
  }

}
