import {Component, HostListener, Inject, Input, OnInit} from '@angular/core';
import {StaticRange} from '../../../models/chart/static-range';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {LessonService} from '../../../modules/training/services/lesson.service';
import {Combination} from '../../../models/combination';
import {UserHotKey} from '../../../models/user/user-hotkey';
import {UserService} from '../../../services/user.service';

@Component({
  selector: 'app-static-range-view',
  templateUrl: './static-range-view.component.html',
  styleUrls: ['./static-range-view.component.scss']
})
export class StaticRangeViewComponent implements OnInit {

  @Input() range: StaticRange;
  @Input() isNextButtonAvailable = false;
  @Input() signedCombination: Combination;
  hotKey: UserHotKey;

  @HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    if (event.code === this.hotKey.keyCode) {
      event.preventDefault();
      this.onCloseButtonClick();
    }
  }

  constructor(public dialogRef: MatDialogRef<StaticRangeViewComponent>, private lessonService: LessonService,
              private userService: UserService,
              @Inject(MAT_DIALOG_DATA) public data: {rangeId: number, secondAction: number,
                isNextButtonAvailable: boolean, signed?: Combination}) {
    this.isNextButtonAvailable = data.isNextButtonAvailable;
    this.signedCombination = data.signed;
    this.lessonService.getRangeById(data.rangeId, data.secondAction, null).subscribe(
      (newRange: StaticRange) => {
        this.range = newRange;
      });
  }

  ngOnInit() {
    this.hotKey = this.userService.getUser().hotKeys.find((hotKey: UserHotKey) => hotKey.action === 'closeHint');
  }

  onCloseButtonClick() {
    this.dialogRef.close(null);
  }
}
