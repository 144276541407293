import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, pluck, switchMap} from 'rxjs/operators';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {HandlerService} from './handler.service';
import {Lesson} from '../models/lesson';
import {CreatingLesson} from '../modules/charts/models/creatingLesson';
import {Combination} from '../models/combination';
import {Chart} from '../models/chart/chart';
import {User} from '../models/user/user';
import {StaticRange} from '../models/chart/static-range';
import {StatsAnswer} from '../models/stats/stats-answer';
import {ChartPack} from '../models/chart/chart-pack';
import {Task} from '../models/task';
import {MistakesInfo} from '../models/stats/mistakes-info';
import {MixedChart} from '../models/chart/mixed-chart';
import {UserTableInfo} from '../models/user/user-table-info';
import {UsersSummary} from '../models/user/users-summary';
import {Purchase} from '../modules/admin/models/purchase';
import {PurchaseTableInfo} from '../modules/admin/models/purchaseTableInfo';
import {PurchaseSummary} from '../modules/admin/models/purchaseSummary';
import {Challenge} from '../models/challenge/challenge';
import {ChallengeLeaderBoard} from '../models/challenge/challenge-leader-board';
import {ChallengeAnswer} from '../models/challenge/challenge-answer';
import {ChallengeResult} from '../models/challenge/challenge-result';
import {DashboardUsers} from '../modules/admin/models/dashboardUsers';
import {DashboardCoaches, DashboardCoachStats} from '../modules/admin/models/dashboardCoaches';
import {DashboardCashFlow} from '../modules/admin/models/dashboardCashFlow';
import {DashboardPartners, DashboardPartnersStats} from '../modules/admin/models/dashboardPartners';
import {CoachPayment} from '../modules/admin/models/coachPayment';
import {PartnerPayment} from '../modules/admin/models/partnerPayment';
import {PartnerReferral} from '../modules/admin/models/partnerReferral';
import {HandsRange} from '../models/hands-range';
import {DashboardCommission} from '../modules/admin/models/dashboardCommission';
import {Student} from '../models/user/student';
import {StudentTableInfo} from '../models/user/student-table-info';
import {HandPrompter} from '../modules/charts/models/hand-promter';
import {Chapter} from '../modules/charts/models/chapter';
import {CreatingAsymmetricLesson} from '../modules/charts/models/creating-asymmetric-lesson';
import {DashboardVisitUser} from '../modules/admin/models/dashboard-visit-user';
import {School} from '../modules/admin/models/school';
import {MistakesChartCombination} from '../models/stats/mistakes-chart-combination';
import {LeakFinderLogRecord} from '../modules/leak-finder/leak-finder.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  backendUrl = 'https://back.preflophero.com/';
  // tslint:disable-next-line:max-line-length
  token = '8570062663399d24cd410d31ef9130f483f3e4f526e471fec9b8a41b47048b1df9f91f7f7c7cd0ae8916d7ec99cfad53d00eafcb96bbc763b986bf6a7590d93b';

  constructor(private httpClient: HttpClient, private handlerService: HandlerService) {
  }

  getLessonById(id: number, mode = 'node', isAsymmetric?: boolean): Observable<Lesson> {
    if (isAsymmetric) {
      return this.getAsymmetricLessonById(id);
    } else {
      return this.httpClient.get(this.backendUrl + 'getLesson?id=' + id + '&mode=' + mode).pipe(
        map(data => this.handlerService.handleLessonData(data))
      );
    }
  }

  getAsymmetricLessonById(id: number): Observable<Lesson> {
    return this.httpClient.get(this.backendUrl + 'getTasksAsymmetric?id=' + id ).pipe(
      map(data => this.handlerService.handleLessonData(data))
    );
  }

  getMixedLessonById(id: number, mode = 'none', stackMin = null, stackMax = null, isAsymmetric = false): Observable<Lesson> {
    if (isAsymmetric) {
      return this.getMixedLessonAsymmetricById(id, mode, stackMin, stackMax);
    } else {
      return this.httpClient.get(this.backendUrl +
        'getMixLesson?id=' + id +
        '&mode=' + mode +
        '&stackMin=' + stackMin +
        '&stackMax=' + stackMax).pipe(
        map(data => this.handlerService.handleLessonData(data))
      );
    }
  }

  getMixedLessonAsymmetricById(id: number, mode = 'none', stackMin = null, stackMax = null): Observable<Lesson> {
    return this.httpClient.get(this.backendUrl +
      'getMixLessonAsymmetryc?id=' + id +
      '&mode=' + mode +
      '&stackMin=' + stackMin +
      '&stackMax=' + stackMax).pipe(
      map(data => this.handlerService.handleLessonData(data))
    );
  }

  getDynamicMixedLessonById(ids: string, mode = 'none', stackMin = null, stackMax = null, isAsymmetric?: boolean): Observable<Lesson> {
    if (isAsymmetric) {
      return this.getAsymmetricDynamicMixedLessonById(ids, mode);
    } else {
      let params = new HttpParams();
      for (const id of ids.split(',')) {
        params = params.append('taskIds[]', id);
      }
      params = params.append('mode', mode);
      params = params.append('stackMin', stackMin);
      params = params.append('stackMax', stackMax);

      return this.httpClient.get(this.backendUrl + 'getDynamicMixLesson', {params}).pipe(
        map(data => this.handlerService.handleLessonData(data))
      );
    }
  }

  getAsymmetricDynamicMixedLessonById(ids: string, mode = 'none'): Observable<Lesson> {

    let params = new HttpParams();
    for (const id of ids.split(',')) {
      params = params.append('taskIds[]', id);
    }
    params = params.append('mode', mode);

    return this.httpClient.get(this.backendUrl + 'getDynamicMixLessonAsymmetric', {params}).pipe(
      map(data => this.handlerService.handleLessonData(data))
    );
  }

  createLesson(newLesson: CreatingLesson): Observable<any> {
    for (const range of newLesson.stackRanges) {
      for (const chart of range.charts) {
        chart.combinations = chart.combinations.join('');
      }
    }

    return this.httpClient.post(this.backendUrl + 'saveTask', newLesson);
  }

  createAsymmetricLesson(newAsymmetricLesson: CreatingAsymmetricLesson): Observable<any> {
    for (const range of newAsymmetricLesson.ranges) {
      range.combinations = range.combinations.join('');
    }

    return this.httpClient.post(this.backendUrl + 'saveChartAsymmetricStack', newAsymmetricLesson);
  }

  createMixedLesson(newLesson: MixedChart): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'createMixLesson', newLesson);
  }

  saveMixLessonAsymmetric(newLesson: MixedChart): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'saveMixLessonAsymmetric', newLesson);
  }

  clearHistory(lessonId: number, lessonType: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteHistory', {taskId: lessonId, type: lessonType});
  }

  deletePack(packId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteGroupChart', {id: packId});
  }

  deleteUserFromSchool(userId: number, schoolId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'removeFromSchool', {
      userId, schoolId
    });
  }

  editPack(packId: number, packTitle: string): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'editPack', {id: packId, title: packTitle});
  }

  copyPack(packId: number, packTitle: string): Observable<ChartPack> {

    return this.httpClient.post(this.backendUrl + 'copyPack', {id: packId, title: packTitle}).pipe(
      map(data => this.handlerService.handleChartPackData(data))
    );
  }

  deleteLesson(lessonId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteChart', {id: lessonId});
  }

  deleteMixedLesson(lessonId: number): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'deleteMixTasks', {id: lessonId});
  }

  getLessons(userId: number): Observable<{ lessons: Lesson[], mixedLessons: Lesson[] }> {
    return this.httpClient.get(this.backendUrl + 'getTasks?userId=' + userId).pipe(
      switchMap((data: any) => {
        return of({lessons: data.tasks, mixedLessons: data.mixes});
      })
    );
  }

  getChartGroups(userId: number): Observable<ChartPack[]> {
    return this.httpClient.get(this.backendUrl + 'getChartGroups?userId=' + userId).pipe(
      map((data: any) => data.map(group => this.handlerService.handleChartPackData(group)))
    );
  }

  getStats(): Observable<Chart[]> {
    return this.httpClient.get(this.backendUrl + 'getStats').pipe(
      map((data: any) => data.taskStats.map((chart) => this.handlerService.handleShortChartData(chart, data.type)))
    );
  }

  getMistakes(chartId: number, handsCount: number = 10,
              elementId: number = null, numberPage: number = null): Observable<{ tasks: Task[], info: MistakesInfo }> {
    return this.httpClient.get(
      this.backendUrl + 'getMistakes?taskId=' + chartId +
      '&elementId=' + elementId + '&count=' + handsCount + '&numberPage=' + numberPage + '&type=0').pipe(
      switchMap((data: any) => {
        return of(
          {
            tasks: data.mistakes.map((mistake) => this.handlerService.handleTaskData(mistake)),
            info: this.handlerService.handleMistakesInfo(data.other)
          });
      })
    );
  }

  getMistakesChart(chartId: number, type: number): Observable<Array<MistakesChartCombination>> {
    return this.httpClient.get(`${this.backendUrl}getMistakesOnHands?taskId=${chartId}&type=${type}`).pipe(
      map(data => this.handlerService.handleMistakesChart(data))
    );
  }

  getAsymmetricMistakes(chartId: number, handsCount: number = 10,
                        elementId: number = null, numberPage: number = null): Observable<{ tasks: Task[], info: MistakesInfo }> {
    return this.httpClient.get(
      this.backendUrl + 'getMistakes?taskId=' + chartId +
      '&elementId=' + elementId + '&count=' + handsCount + '&numberPage=' + numberPage + '&type=1').pipe(
      switchMap((data: any) => {
        return of(
          {
            tasks: data.mistakes.map((mistake) => this.handlerService.handleTaskData(mistake)),
            info: this.handlerService.handleMistakesInfo(data.other)
          });
      })
    );
  }

  getUsers(count: number = 10, elementId: number = null, numberPage: number = null,
           email: string = '', sortType?: number, sortTotal?: string, schoolId?: number):
    Observable<{ users: User[], info: UserTableInfo, summary: UsersSummary }> {
    return this.httpClient.get(
      this.backendUrl + 'getUsers?elementId=' + elementId
      + '&count=' + count + '&numberPage=' + numberPage + '&type=' + sortType + '&sortByTotal=' + sortTotal
      + '&email=' + email + '&schoolId=' + schoolId).pipe(
      switchMap((data: any) => {
        return of(
          {
            users: data.users.map((user) => this.handlerService.handleUserDataForAdmin(user)),
            info: this.handlerService.handleUserTableInfo(data.other),
            summary: this.handlerService.handleUserSummary(data.summary)
          });
      })
    );
  }

  getUsersBySchoolId(schoolId: number):
    Observable<User[]> {
    const sortTotal = '';
    const email = '';
    return this.httpClient.get(
      this.backendUrl + 'getUsers?elementId=null'
      + '&count=null' + '&numberPage=null' + '&type=null' + '&sortByTotal=' + sortTotal
      + '&email=' + email + '&schoolId=' + schoolId).pipe(
      switchMap((data: any) => {
        return of(
          data.users.map((user) => this.handlerService.handleUserDataForAdmin(user))
        );
      })
    );
  }

  getPurchases(count: number = 10, elementId: number = null, numberPage: number = null):
    Observable<{ purchases: Purchase[], info: PurchaseTableInfo, summary: PurchaseSummary }> {

    return this.httpClient.get(
      this.backendUrl + 'getPurchases?elementId=' + elementId
      + '&count=' + count + '&numberPage=' + numberPage).pipe(
      switchMap((data: any) => {
        return of(
          {
            purchases: data.purchases.map((purchase) => this.handlerService.handlePurchaseDataForAdmin(purchase)),
            info: this.handlerService.handlePurchaseTableInfo(data.other),
            summary: this.handlerService.handlePurchaseSummary(data.summary)
          });
      })
    );
  }

  getDashboardStat():
    Observable<{users: DashboardUsers, coaches: DashboardCoaches, cashFlow: DashboardCashFlow,
      partners: DashboardPartners, commissions: DashboardCommission[], visits: DashboardVisitUser}> {

    return this.httpClient.get(
      this.backendUrl + 'getDashboardStat').pipe(
      switchMap((data: any) => {
        return of({
          users: this.handlerService.handleDashboardUsers(data.users),
          coaches: this.handlerService.handleDashboardCoaches(data.coaches),
          cashFlow: this.handlerService.handleDashboardCashFlow(data.cashFlow),
          partners: this.handlerService.handleDashboardPartners(data.partners),
          commissions: this.handlerService.handleDashboardCommissions(data.commissions),
          visits: this.handlerService.handleDashboardVisits(data.visits)
        });
      })
    );
  }

  getCoaches(): Observable<{ coaches: DashboardCoaches[] }> {

    return this.httpClient.get(
      this.backendUrl + 'getDashboardCoaches').pipe(
      switchMap((data: { coaches: DashboardCoaches[] }) => {
        return of({
          coaches: data.coaches.map((coach) => this.handlerService.handleDashboardCoaches(coach))
        });
      })
    );
  }

  getCoachById(coachId: number): Observable<{ coach: DashboardCoaches, payments: CoachPayment[] }> {

    return this.httpClient.get(
      `${this.backendUrl}getDashboardCoach?coach=${coachId}`).pipe(
      switchMap((data: {
        id: number,
        coachTitle: string,
        currentMonth: DashboardCoachStats,
        lastMonth: DashboardCoachStats,
        allTime: DashboardCoachStats,
        payments: CoachPayment[]
      }) => {
        const coachInfo = {
          id: data.id,
          coachTitle: data.coachTitle,
          currentMonth: data.currentMonth,
          lastMonth: data.lastMonth,
          allTime: data.allTime
        } as DashboardCoaches;
        return of({
          coach: this.handlerService.handleDashboardCoaches(coachInfo),
          payments: data.payments.map((payment) => this.handlerService.handleCoachPayment(payment))
        });
      })
    );
  }

  getPartners(): Observable<{ partners: DashboardPartners[] }> {

    return this.httpClient.get(
      this.backendUrl + 'getDashboardPartners').pipe(
      switchMap((data: { partners: DashboardPartners[] }) => {
        return of({
          partners: data.partners.map((partner) => this.handlerService.handleDashboardPartners(partner))
        });
      })
    );
  }

  getPartnerById(partnerId: number):
    Observable<{ partner: DashboardPartners, payments: PartnerPayment[], referrals: PartnerReferral[] }> {

    return this.httpClient.get(
      `${this.backendUrl}getDashboardPartner?partner=${partnerId}`).pipe(
      switchMap((data: {
        id: number,
        partnerTitle: string,
        currentMonth: DashboardPartnersStats,
        lastMonth: DashboardPartnersStats,
        allTime: DashboardPartnersStats,
        payments: CoachPayment[],
        referrals: PartnerReferral[]
      }) => {
        const partnerInfo = {
          id: data.id,
          partnerTitle: data.partnerTitle,
          currentMonth: data.currentMonth,
          lastMonth: data.lastMonth,
          allTime: data.allTime
        } as DashboardPartners;
        return of({
          partner: this.handlerService.handleDashboardPartners(partnerInfo),
          payments: data.payments.map((payment) => this.handlerService.handlePartnerPayment(payment)),
          referrals: data.referrals.map((referral) => this.handlerService.handlePartnerReferral(referral))
        });
      })
    );
  }

  getSchools(): Observable<School[]> {

    return this.httpClient.get(
      this.backendUrl + 'getSchools').pipe(
      switchMap((data: { schools: School[] }) => {
        return of(data.schools.map((school) => this.handlerService.handleSchool(school)));
      })
    );
  }

  addPurchase(purchase: Purchase): Observable<Purchase> {

    const postPurchase = {
      date: purchase.date,
      emails: purchase.email,
      paymentId: purchase.paymentMethod.id,
      sum: purchase.sum,
      productId: purchase.product.title,
      promocode: purchase.promocode,
      comment: purchase.comment,
    };

    return this.httpClient.post(this.backendUrl + 'newManualPurchase', postPurchase).pipe(
      map((purchases: any) => this.handlerService.handlePurchaseDataForAdmin(purchases.data))
    );
  }

  getChartGroup(groupId: number, userId: number): Observable<{
    charts: Chart[], mixCharts: Chart[],
    uuid?: string, isEditable?: boolean
  }> {
    return this.httpClient.get(this.backendUrl + 'getChartGroup?userId=' + userId + '&id=' + groupId).pipe(
      switchMap((data: any) => {
        return of(
          {
            charts: data.charts ? data.charts.map((chart) => this.handlerService.handleShortChartData(chart)) : [],
            mixCharts: data.mixCharts ? data.mixCharts.map((chart) => this.handlerService.handleShortChartData(chart)) : [],
            uuid: data.uuid,
            isEditable: data.isEditable,
            title: data.title,
            type: data.type
          });
      })
    );
  }

  getAsymmetricChartGroup(groupId: number, userId: number): Observable<{
    charts: Chart[], mixCharts: Chart[],
    uuid?: string, isEditable?: boolean
  }> {
    return this.httpClient.get(this.backendUrl + 'getChartGroupAsymmetricStack?userId=' + userId + '&id=' + groupId).pipe(
      switchMap((data: any) => {
        return of(
          {
            charts: data.charts.map((chart) => this.handlerService.handleShortChartData(chart)),
            mixCharts: data.mixCharts.map((chart) => this.handlerService.handleShortChartData(chart)),
            uuid: data.uuid,
            isEditable: data.isEditable,
            title: data.title,
            type: data.type
          });
      })
    );
  }

  getChapters(groupChartId: string | number): Observable<Chapter[]> {
    return this.httpClient.get(this.backendUrl + 'getChapters?groupChartId=' + groupChartId).pipe(
      map((data: any) => data.map(chapter => this.handlerService.handleChapterData(chapter)))
    );
  }

  // getAsymmetricChapters(groupChartId: string | number): Observable<Chapter[]> {
  //   return this.httpClient.get(this.backendUrl + '?groupChartId=' + groupChartId).pipe(
  //     map((data: any) => data.map(chapter => this.handlerService.handleChapterData(chapter)))
  //   );
  // }

  saveChapters(chapters: Chapter[], groupTasksId: string): Observable<any> {
    const postChapters = chapters.map((chapter) => {
      return {id: chapter.id, title: chapter.title};
    });

    return this.httpClient.post(this.backendUrl
      + 'saveChapters', {chapters: postChapters, groupTasksId});
  }

  createChartGroup(chartPack): Observable<ChartPack> {
    return this.httpClient.post(this.backendUrl + 'createChartGroup', chartPack).pipe(
      map(data => this.handlerService.handleChartPackData(data))
    );
  }
  getFlashAnswer(packId: number, effectiveStack: number, hand: number): Observable<HandPrompter> {
    return this.httpClient.get(this.backendUrl
      + 'getSolutions?packId=' + packId + '&effectiveStack=' + effectiveStack + '&hand=' + hand).pipe(
      pluck('solutions'),
      map((data: any) => this.handlerService.handleHandPrompterData(data))
    );
  }

  getChartById(id: number): Observable<Chart> {
    return this.httpClient.get(this.backendUrl + 'getChart?id=' + id).pipe(
      pluck('chart'),
      map((data: any) => this.handlerService.handleShortChartData(data))
    );
  }

  getAsymmetricChartById(id: number): Observable<Chart> {
    return this.httpClient.get(this.backendUrl + 'getChartAsymmetricStack?id=' + id).pipe(
      pluck('chart'),
      map((data: any) => this.handlerService.handleShortChartData(data))
    );
  }

  getMixedChartById(id: number): Observable<MixedChart> {
    return this.httpClient.get(this.backendUrl + 'getMixedChart?id=' + id).pipe(
      map((data: any) => this.handlerService.handleMixedChartData(data))
    );
  }

  getMixedAsymmetricChartById(id: number): Observable<MixedChart> {
    return this.httpClient.get(this.backendUrl + 'getMixedChartAsymmetric?id=' + id).pipe(
      map((data: any) => this.handlerService.handleMixedChartData(data))
    );
  }

  getHandsRanges(): Observable<HandsRange[]> {
    return this.httpClient.get(this.backendUrl + 'getHandsRanges').pipe(
      map((data: any) => data.map(range => this.handlerService.handleHandsRange(range)))
    );
  }

  getHandsRangeById(id: number): Observable<HandsRange> {
    return this.httpClient.get(this.backendUrl + 'getHandRange?id=' + id).pipe(
      map((data: any) =>  this.handlerService.handleHandsRange(data))
    );
  }

  saveHandsRange(range: HandsRange, type = 0): Observable<number> {
    const newRange: any = {};
    newRange.id = range.id;
    newRange.title = range.title;
    newRange.type = type;
    newRange.combinations = range.combinations.filter(
      (combination: Combination) => combination.isFilled).map((combination: Combination) => combination.id);

    return this.httpClient.post(this.backendUrl + 'saveHandsRange', newRange).pipe(
      map((data: any) => data.id)
    );
  }

  getChallenges(): Observable<{ challenges: Array<Challenge>, leaderBoard: ChallengeLeaderBoard }> {
    return this.httpClient.get(this.backendUrl + 'getChallenges').pipe(
      switchMap((data: any) => {
        return of(
          {
            challenges: data.challenges.map((challenge) => this.handlerService.handleChallengeData(challenge)),
            leaderBoard: this.handlerService.handleChallengeLeaderBoard(data.leaderboard)
          });
      })
    );
  }

  getChallenge(id: string = null, test: number = 0): Observable<{ isAvailable: boolean, tasksLeft: number, tasks: Task[] }> {
    return this.httpClient.get(this.backendUrl + 'getChallenge?id=' + id + '&test=' + test).pipe(
      switchMap((data: any) => {
        const newTasks = data.tasks.map((task: any) => {
          const newTask = this.handlerService.handleTaskData(task.data);
          newTask.id = task.id;
          return newTask;
        });
        return of({isAvailable: data.isAvailable, tasksLeft: data.taskLeft, tasks: newTasks});
      })
    );
  }

  getChallengeResult(test: number = 0): Observable<ChallengeResult> {
    return this.httpClient.get(this.backendUrl + 'getChallengeResult?test=' + test).pipe(
      switchMap((data: any) => {
        return of({title: data.title, isComplete: !data.isAvailable, correct: data.correct, all: data.all});
      })
    );
  }

  checkInvite(): Observable<{ isExist: boolean, user: User }> {
    return this.httpClient.get(this.backendUrl + 'auth').pipe(
      switchMap((data: any) => {
        if (data.isExist) {
          return of({isExist: true, user: this.handlerService.handleUserData(data)});
        } else {
          return of({isExist: false, user: null});
        }
      })
    );
  }

  checkAuth(code: string, ref: string): Observable<{ isExist: boolean, user: User, token: string }> {
    return this.httpClient.post(this.backendUrl + 'connect/google/check', {code, ref}).pipe(
      switchMap((data: any) => {
        if (data.isExist) {
          return of({isExist: true, user: this.handlerService.handleUserData(data.profile), token: data.token});
        } else {
          return of({isExist: false, user: null, token: null});
        }
      })
    );
  }

  getRangeById(rangeId: number, secondAction: number, secondActionFactor: number, isAsymmetric?: boolean): Observable<StaticRange> {
    if (isAsymmetric) {
      return this.getAsymmetricRangeById(rangeId);
    } else {
      return this.httpClient.get(this.backendUrl + 'getRange?id=' + rangeId + '&secondAction=' + secondAction
        + '&secondActionFactor=' + secondActionFactor).pipe(
        map(data => this.handlerService.handleStaticRangeData(data))
      );
    }
  }

  getAsymmetricRangeById(rangeId: number): Observable<StaticRange> {
    return this.httpClient.get(this.backendUrl + 'getRangeAsymmetric?id=' + rangeId).pipe(
      map(data => this.handlerService.handleStaticRangeData(data))
    );
  }

  updateUser(user: User): Observable<User> {
    const updatedUser = {
      id: user.id,
      round: user.settings.roundTo,
      isMailing: user.settings.isMailing,
      showEffective: user.settings.isEffectiveStackShown,
      stackView: user.settings.stackView.id,
      language: user.language,
      random: user.settings.randomizer,
      username: user.nickname,
      groupId: user.activeChartGroup.id,
      stackMin: user.stackSize.stackMin,
      stackMax: user.stackSize.stackMax,
      hotkeys: user.hotKeys,
      avatar: user.settings.avatar,
      leakFinderNick: user.settings.leakFinderNick,
      leakFinderNickChanged: user.settings.leakFinderNickChanged ? user.settings.leakFinderNickChanged.toString() : '',
      leakFinderSettings: {
        sources: user.leakFinderSettings.sources,
      },
    };

    return this.httpClient.post(this.backendUrl + 'saveUser', updatedUser);
  }

  applyPromoCode(promocode: string): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'setPromocodeSchool', {code: promocode});
  }

  applyPromocodeSPNS(promocode: string): Observable<any> {

    return this.httpClient.post(this.backendUrl + 'activateSchool', {code: promocode});
  }

  saveStatsAnswer(answer: StatsAnswer): Observable<any> {
    return this.httpClient.post(this.backendUrl + 'setAnswer', answer).pipe(
      map((data: any) => this.handlerService.handleSetAnswerData(data))
    );
  }

  saveChallengeAnswer(answer: ChallengeAnswer): Observable<any> {
    return this.httpClient.post(this.backendUrl + 'setAnswerChallenge', answer);
  }


  // school

  getStudentList(count: number = 10, elementId: number = null, numberPage: number = null):
    Observable<{ students: Student[], info: StudentTableInfo }> {
    return this.httpClient.get(this.backendUrl + 'getPupils?elementId=' + elementId
      + '&count=' + count + '&numberPage=' + numberPage).pipe(
      switchMap((data: any) => {
        return of(
          {
            students: data.pupils.map((student: any) => this.handlerService.handleStudentData(student)),
            info: this.handlerService.handleStudentTableInfo(data.other)
          });
      })
    );
  }

  getStudentStats(id: number): Observable<Chart[]> {
    return this.httpClient.get(this.backendUrl + 'getPupilStat?id=' + id).pipe(
      map((data: any) => data.taskStats.map((chart) => this.handlerService.handleShortChartData(chart)))
    );
  }

  registerPurchase(purchase: Purchase) {
    const headers = new HttpHeaders({
      'X-AUTH-TOKEN': this.token,
    });
    const postPurchase = {
      date: purchase.date,
      emails: [purchase.email],
      paymentId: purchase.paymentMethod.id,
      sum: purchase.sum,
      count: purchase.count ? purchase.count : 1,
      productId: purchase.product.title,
      promocode: purchase.promocode,
      comment: purchase.comment,
    };

    return this.httpClient.post(this.backendUrl + 'newManualPurchase', postPurchase, { headers });
  }

  loadChartsByPackId(packId: number): Observable<Chart[]> {
    return this.httpClient.get(this.backendUrl + 'getCharts?packId=' + packId).pipe(
      switchMap((data: any) => {
        return of(data.charts ? data.charts.map((chart) => this.handlerService.handleShortChartData(chart)) : []);
      })
    );
  }

  sendLeakFinderLog(log: LeakFinderLogRecord): Observable<any> {
    return this.httpClient.post(this.backendUrl + 'user/leakFinderLog', log);
  }

  getLeakFinderLog(): Observable<LeakFinderLogRecord[]> {
    return this.httpClient.get(this.backendUrl + 'user/leakFinderLog').pipe(
      map((data: any) => {
        return data ? data.map((value) => this.handlerService.handleLeakFinderLogData(value)) : [];
      })
    );
  }

  deleteLeakFinderLog(id: string): Observable<any> {
    return this.httpClient.delete(this.backendUrl + `user/leakFinderLog/${id}`);
  }
}
