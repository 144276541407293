import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Metadata} from '../../models/metadata';

@Component({
  selector: 'app-avatar-picker',
  templateUrl: './avatar-picker.component.html',
  styleUrls: ['./avatar-picker.component.scss']
})
export class AvatarPickerComponent implements OnInit {

  @Input() avatarId = 0;
  @Input() options: Array<Metadata> = [];
  @Output() avatarUpdated: EventEmitter<number> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  onAvatarChanged(newId: number) {
    this.avatarId = newId;
    this.avatarUpdated.emit(this.avatarId);
  }

}
