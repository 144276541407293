import {Component, Input, OnInit} from '@angular/core';
import {TaskAnswer} from '../../../models/task-answer';
import {ViewInfo} from '../../../models/user/view-info';
import { UserHotKey } from '../../../models/user/user-hotkey';

@Component({
  selector: 'app-action-button',
  templateUrl: './action-button.component.html',
  styleUrls: ['./action-button.component.scss']
})
export class ActionButtonComponent implements OnInit {

  @Input() action: TaskAnswer;
  @Input() viewInfo: ViewInfo;
  @Input() hotKey: UserHotKey;

  constructor() {
  }

  ngOnInit() {
  }


}
