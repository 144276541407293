import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'stackTransform'
})
export class StackToBbPipe implements PipeTransform {

  transform(stack: number, isToBb?: boolean, bb?: number, roundTo?: number, symbol?: string): string {
    if (isToBb) {
      const stackSize = round(stack / bb, roundTo);
      return stackSize.toString() + ' bb';
    } else {
      symbol = symbol === undefined ? '$' : symbol;
      return symbol + stack.toString();
    }
  }
}

function round(value, decimals) {
  return Number(Math.round(+(value + 'e' + decimals)) + 'e-' + decimals);
}
