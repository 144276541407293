import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'productName'
})
export class ProductNamePipe implements PipeTransform {

  transform(product: string): string {

    switch (product) {
      case 'ph-sub-1':
        return '1 month';
      case 'ph-sub-3':
        return '3 months';
      case 'ph-sub-12':
        return '12 months';
      case 'ph-chart-1':
        return 'chart';
      case 'ph-chart-2':
        return 'sf chart';
      case 'ph-chart-3':
        return 'gto pack';
      case 'sf-school':
        return 'sf chart';
      case 'bitb-school':
        return 'bitb chart';
      case 'bitb-school-new':
        return 'bitb chart';
      case 'asymmetric-pack':
        return 'asymmetric';
      case 'sf-ns-course':
        return 'sp&ns course';
      case 'firestorm-school':
        return 'firestorm school';
      case 'nitro-pack':
        return 'nitro';
      case 'nash-pack':
        return 'nash';
      case 'ph-sub-14-days':
        return '14-days';
      case 'hs-school':
        return 'hyper school';
      case 'plastilin':
        return 'plastilin pack';
      case 'pokerhero-spin':
        return 'pokerhero-spin';
      case 'pokerhero-flash':
        return 'pokerhero-flash';
      case 'pokerhero-trial':
        return 'pokerhero-trial';
      case 'flow-1':
        return 'flow-1';
      case 'lf-upto-5':
        return 'LF upto 5';
      case 'lf-upto-10':
        return 'LF upto 10';
      case 'lf-upto-25':
        return 'LF upto 25';
      case 'lf-upto-50':
        return 'LF upto 50';
      case 'lf-upto-100':
        return 'LF upto 100';
      case 'lf-upto-5-3':
        return 'LF 3 months upto 5';
      case 'lf-upto-10-3':
        return 'LF 3 months upto 10';
      case 'lf-upto-25-3':
        return 'LF 3 months upto 25';
      case 'lf-upto-50-3':
        return 'LF 3 months upto 50';
      case 'lf-upto-100-3':
        return 'LF 3 months upto 100';
      case 'lf-upto-5-12':
        return 'LF 1 year upto 5';
      case 'lf-upto-10-12':
        return 'LF 1 year upto 10';
      case 'lf-upto-25-12':
        return 'LF 1 year upto 25';
      case 'lf-upto-50-12':
        return 'LF 1 year upto 50';
      case 'lf-upto-100-12':
        return 'LF 1 year upto 100';
      case 'lf-upgrade-5':
        return 'LF upgrade 5';
      case 'lf-upgrade-10':
        return 'LF upgrade 10';
      case 'lf-upgrade-25':
        return 'LF upgrade 25';
      case 'lf-upgrade-50':
        return 'LF upgrade 50';
      case 'lf-upgrade-100':
        return 'LF upgrade 100';
      default:
        return 'unknown';
    }

  }
}
