import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Player } from '../../../models/player';
import { ViewInfo } from '../../../models/user/view-info';

@Component({
  selector: 'app-wm-player-hud-bot',
  templateUrl: './wm-player-hud-bot.component.html',
  styleUrls: ['./wm-player-hud-bot.component.scss']
})
export class WmPlayerHudBotComponent {
  @HostBinding('class.app-wm-player-hud-bot')
  public readonly hasHostClassName: boolean = true;

  @Input() player: Player;
  @Input() viewInfo: ViewInfo;
}
