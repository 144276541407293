import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UserTableInfo} from '../../../models/user/user-table-info';
import {Purchase} from '../../../modules/admin/models/purchase';
import {CommentDialogComponent} from '../../../modules/admin/components/comment-dialog/comment-dialog.component';
import {MatDialog} from '@angular/material/dialog';

@Component({
  selector: 'app-purchases-table',
  templateUrl: './purchases-table.component.html',
  styleUrls: ['./purchases-table.component.scss']
})
export class PurchasesTableComponent implements OnInit {

  @Input() purchases: Purchase[];
  @Input() info: UserTableInfo;

  @Output() pageNumber = new EventEmitter<number>();

  constructor(private dialog: MatDialog) { }

  clickToSwitchPage(pageNumber: number) {

    if (pageNumber !== this.info.currentPage) {
      this.pageNumber.emit(pageNumber);

    }

  }

  ngOnInit() {
  }

  openComment(comment: string) {
    this.dialog.open(CommentDialogComponent, {
      data: comment
    });
  }

}
