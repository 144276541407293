import {Injectable} from '@angular/core';
import * as Centrifuge from 'centrifuge';
import {UserService} from './user.service';
import {SnackbarService} from '../shared/services/snackbar.service';
import {Router} from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {

  centrifuge: Centrifuge;

  constructor(private userService: UserService,
              private snackbarService: SnackbarService,
              private router: Router) {
  }

  init(protocol: string) {
    const url = protocol + '://centrifugo.preflophero.com/connection/websocket';
    this.centrifuge = new Centrifuge(
      url
    );
  }

  connect() {

    if (!this.centrifuge.isConnected()) {
      this.centrifuge.subscribe(
        `public:${this.userService.getToken().toString()}`, (message: { seq: number, data: {product: string} }) => {
          switch (message.data.product) {
            case 'ph-sub-1':
            case 'ph-sub-12':
            case 'ph-sub-3':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage('Your subscription is activated');
              });
              break;
            case 'ph-chart-1':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`SawCrew's chart is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'ph-chart-2':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`Steel Phoenix chart is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'sf-ns-course':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`Steel Phoenix course chart is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'ph-chart-3':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`GTO pack is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'asymmetric-pack':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`Asymmetric train is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'bitb-school-new':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`BTB pack is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'nash-pack':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`Nash pack is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'nitro-pack':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`Nitro pack is added`);
                this.router.navigate(['training']);
              });
              break;
            case 'sf-school':
              this.userService.checkInvite().subscribe(() => {
                this.snackbarService.showMessage(`School is activated`);
                this.router.navigate(['training']);
              });
              break;
            default:
              break;
          }
        });

      this.centrifuge.connect();
    }
  }


}
