import {Component, Input, OnInit} from '@angular/core';
import {Combination} from '../../../models/combination';
import {UtilsService} from '../../services/utils.service';

@Component({
  selector: 'app-static-chart',
  templateUrl: './static-chart.component.html',
  styleUrls: ['./static-chart.component.scss']
})
export class StaticChartComponent implements OnInit {

  @Input() combinations: string[];
  @Input() signedCombination: Combination;

  constructor(private utilsService: UtilsService) { }

  isSigned(index: number): boolean {
    if (!this.signedCombination) {
      return false;
    }
    return index === this.signedCombination.id;
  }

  ngOnInit() {
  }

  getToolTipForCombination(combination: string): string {
    return this.utilsService.getToolTipForCombination(combination);
  }

  isToolTipAvailable(combination: string): boolean {
    return this.utilsService.isToolTipAvailable(combination);
  }

}
