import { Component, Input, OnInit } from '@angular/core';
import { Chips } from '../../../models/chips';
import { DefaultDataService } from '../../../services/default-data.service';

@Component({
  selector: 'app-wm-chips',
  templateUrl: './wm-chips.component.html',
  styleUrls: ['./wm-chips.component.scss']
})
export class WmChipsComponent implements OnInit {
  @Input() chipsCount: number;
  defaultChips: Chips[] = [];

  wmChips: Chips[] = [
    {nominal: 1, color: '#242424', borderColor: '#5D5D5D'},
    {nominal: 1, color: '#242424', borderColor: '#5D5D5D'},
    {nominal: 1, color: '#242424', borderColor: '#5D5D5D'},
    {nominal: 1, color: '#242424', borderColor: '#5D5D5D'},
  ];

  constructor() {
    this.defaultChips = this.wmChips;
  }

  ngOnInit() {
  }

}
