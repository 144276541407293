import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
// components
import {LoginComponent} from './components/login/login.component';
import {RootComponent} from './components/root/root.component';
import {ScreenSizeErrorComponent} from './components/screen-size-error/screen-size-error.component';
// guards
import {AuthGuard} from './guards/auth.guard';
import {LoginGuard} from './guards/login.guard';
import {ScreenSizeGuard} from './guards/screen-size.guard';


const routes: Routes = [
  {
    path: '',
    component: RootComponent,
    children: [
      {
        path: 'training',
        loadChildren: () => import('./modules/training/training.module').then(m => m.TrainingModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: { breadcrumb: 'training' }
      },
      {
        path: 'stats',
        loadChildren: () => import('./modules/statistic/statistic.module').then(m => m.StatisticModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: {breadcrumb: 'statistic'}
      },
      {
        path: 'settings',
        loadChildren: () => import('./modules/settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: { breadcrumb: 'settings' }
      },
      {
        path: 'charts',
        loadChildren: () => import('./modules/charts/charts.module').then(m => m.ChartsModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: {breadcrumb: 'charts'}
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: {breadcrumb: 'Dashboard'}
      },
      {
        path: 'challenge',
        loadChildren: () => import('./modules/challenge/challenge.module').then(m => m.ChallengeModule),
        canActivate: [AuthGuard, ScreenSizeGuard]
      },
      {
        path: 'partner',
        loadChildren: () => import('./modules/partner/partner.module').then(m => m.PartnerModule),
        canActivate: [AuthGuard, ScreenSizeGuard]
      },
      {
        path: 'coach',
        loadChildren: () => import('./modules/coach/coach.module').then(m => m.CoachModule),
        canActivate: [AuthGuard, ScreenSizeGuard]
      },
      {
        path: 'students',
        loadChildren: () => import('./modules/school/school.module').then(m => m.SchoolModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: {breadcrumb: 'School'}
      },
      {
        path: 'moderation',
        loadChildren: () => import('./modules/moderation/moderation.module').then(m => m.ModerationModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: {breadcrumb: 'moderation'}
      },
      {
        path: 'leak-finder',
        loadChildren: () => import('./modules/leak-finder/leak-finder.module').then(m => m.LeakFinderModule),
        canActivate: [AuthGuard, ScreenSizeGuard],
        data: {breadcrumb: 'leak-finder'}
      },
    ],
    canActivate: [AuthGuard, ScreenSizeGuard]
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard, ScreenSizeGuard]
  },
  {
    path: 'screen-size',
    component: ScreenSizeErrorComponent,
  }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
