import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {Metadata} from '../../models/metadata';
import {ClubService} from '../../services/club.service';

const DEFAULT_META = {
  name: 'PokerHero #0',
  description: 'cool',
  image: 'https://api.pokerheroclub.io/image/0.png',
  edition: 0,
  id: 0,
  attributes: [
    {trait_type: 'Background', value: 'frost'},
    {trait_type: 'Hero Type', value: 'hero'},
    {trait_type: 'Head', value: 'None'},
    {trait_type: 'Mouth', value: 'classy'},
    {trait_type: 'Ear', value: 'None'},
    {trait_type: 'Eyes', value: 'aviator green'}
  ]
};

@Component({
  selector: 'app-avatar-viewer',
  templateUrl: './avatar-viewer.component.html',
  styleUrls: ['./avatar-viewer.component.scss']
})
export class AvatarViewerComponent implements OnInit {
  @HostBinding('class.app-avatar-viewer')
  public readonly isDefaultClassUsed = true;

  @Input() set avatarId(id: number) {
    if (id !== 0) {
      this.clubService.metadataById(id).subscribe(meta => {
        this.metadata = meta;
      });
    } else {
      this.metadata = DEFAULT_META;
    }
  }

  metadata: Metadata = DEFAULT_META;

  constructor(private clubService: ClubService) {
  }

  ngOnInit() {
  }

}
