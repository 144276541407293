import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
// models
import {Student} from '../../../models/user/student';
import {StudentTableInfo} from '../../../models/user/student-table-info';

@Component({
  selector: 'app-student-table',
  templateUrl: './student-table.component.html',
  styleUrls: ['./student-table.component.scss']
})
export class StudentTableComponent implements OnInit {

  @Input() students: Student[];
  @Input() info: StudentTableInfo;
  @Output() studentSelected = new EventEmitter<number>();

  @Output() pageNumber = new EventEmitter<number>();

  constructor() { }

  clickToSwitchPage(pageNumber: number) {

    if (pageNumber !== this.info.currentPage) {
      this.pageNumber.emit(pageNumber);

    }
  }

  ngOnInit() {
  }

  showStudentStats(id: number) {
    this.studentSelected.emit(id);
  }

}
