import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PaypalBuyDialogComponent } from '../components/paypal-buy-dialog/paypal-buy-dialog.component';
import {timer} from 'rxjs';
import {tap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PaypalProductService {

  constructor(private dialog: MatDialog) {
  }

  buyProduct(productId: string, cost?: number, isCountAvailable?: boolean) {
      this.dialog.open(PaypalBuyDialogComponent, {
        autoFocus: false,
        data: {
          productId,
          cost,
          isCountAvailable,
        }
      });
  }

  checkForQuickPurchases() {
    const productId = localStorage.getItem('buy');
    if (productId) {
      localStorage.removeItem('buy');
      this.buyProductWithDelay(productId);
    }
  }

  buyProductWithDelay(id: string) {
    timer(500).pipe(
      tap(() => this.buyProduct(id))
    ).subscribe();
  }
}
