import {Injectable} from '@angular/core';
// lodash
import * as _ from 'lodash';
// models
import {ChartPosition} from '../models/chart/chart-position';
import {Chips} from '../models/chips';
import {ChartGameType} from '../models/chart/chart-game-type';
import {ChartAction} from '../models/chart/chart-action';
import {Answer} from '../modules/charts/models/answer';
import {Combination} from '../models/combination';
import {PlayerAction} from '../models/player-action';
import {OpponentType} from '../modules/charts/models/opponentType';
import {StackView} from '../models/user/stack-view';
import {ChartColor} from '../models/chart-color';
import {RandomRange} from '../models/chart/random-range';
import {StackSize} from '../modules/charts/models/stackSize';
import {Currency} from '../modules/charts/models/currency';
import {StackViewSetting} from '../modules/charts/models/stack-view-setting';
import {AmountView} from '../modules/charts/models/amount-view';
import {UserHotKey} from '../models/user/user-hotkey';
import {PaymentMethod} from '../modules/admin/models/paymentMethod';
import {Product} from '../modules/admin/models/product';
import {GameMode} from '../models/game-mode';

@Injectable({
  providedIn: 'root'
})
export class DefaultDataService {

  defaultCombinations: Combination[] = [
    {id: 0, title: 'AA'}, {id: 1, title: 'AKs'}, {id: 2, title: 'AQs'}, {id: 3, title: 'AJs'}, {id: 4, title: 'ATs'},
    {id: 5, title: 'A9s'}, {id: 6, title: 'A8s'}, {id: 7, title: 'A7s'}, {id: 8, title: 'A6s'}, {id: 9, title: 'A5s'},
    {id: 10, title: 'A4s'}, {id: 11, title: 'A3s'}, {id: 12, title: 'A2s'}, {id: 13, title: 'AKo'},
    {id: 14, title: 'KK'}, {id: 15, title: 'KQs'}, {id: 16, title: 'KJs'}, {id: 17, title: 'KTs'},
    {id: 18, title: 'K9s'}, {id: 19, title: 'K8s'}, {id: 20, title: 'K7s'}, {id: 21, title: 'K6s'},
    {id: 22, title: 'K5s'}, {id: 23, title: 'K4s'}, {id: 24, title: 'K3s'}, {id: 25, title: 'K2s'},
    {id: 26, title: 'AQo'}, {id: 27, title: 'KQo'}, {id: 28, title: 'QQ'}, {id: 29, title: 'QJs'},
    {id: 30, title: 'QTs'}, {id: 31, title: 'Q9s'}, {id: 32, title: 'Q8s'}, {id: 33, title: 'Q7s'},
    {id: 34, title: 'Q6s'}, {id: 35, title: 'Q5s'}, {id: 36, title: 'Q4s'}, {id: 37, title: 'Q3s'},
    {id: 38, title: 'Q2s'}, {id: 39, title: 'AJo'}, {id: 40, title: 'KJo'}, {id: 41, title: 'QJo'},
    {id: 42, title: 'JJ'}, {id: 43, title: 'JTs'}, {id: 44, title: 'J9s'}, {id: 45, title: 'J8s'},
    {id: 46, title: 'J7s'}, {id: 47, title: 'J6s'}, {id: 48, title: 'J5s'}, {id: 49, title: 'J4s'},
    {id: 50, title: 'J3s'}, {id: 51, title: 'J2s'}, {id: 52, title: 'ATo'}, {id: 53, title: 'KTo'},
    {id: 54, title: 'QTo'}, {id: 55, title: 'JTo'}, {id: 56, title: 'TT'}, {id: 57, title: 'T9s'},
    {id: 58, title: 'T8s'}, {id: 59, title: 'T7s'}, {id: 60, title: 'T6s'}, {id: 61, title: 'T5s'},
    {id: 62, title: 'T4s'}, {id: 63, title: 'T3s'}, {id: 64, title: 'T2s'}, {id: 65, title: 'A9o'},
    {id: 66, title: 'K9o'}, {id: 67, title: 'Q9o'}, {id: 68, title: 'J9o'}, {id: 69, title: 'T9o'},
    {id: 70, title: '99'}, {id: 71, title: '98s'}, {id: 72, title: '97s'}, {id: 73, title: '96s'},
    {id: 74, title: '95s'}, {id: 75, title: '94s'}, {id: 76, title: '93s'}, {id: 77, title: '92s'},
    {id: 78, title: 'A8o'}, {id: 79, title: 'K8o'}, {id: 80, title: 'Q8o'}, {id: 81, title: 'J8o'},
    {id: 82, title: 'T8o'}, {id: 83, title: '98o'}, {id: 84, title: '88'}, {id: 85, title: '87s'},
    {id: 86, title: '86s'}, {id: 87, title: '85s'}, {id: 88, title: '84s'}, {id: 89, title: '83s'},
    {id: 90, title: '82s'}, {id: 91, title: 'A7o'}, {id: 92, title: 'K7o'}, {id: 93, title: 'Q7o'},
    {id: 94, title: 'J7o'}, {id: 95, title: 'T7o'}, {id: 96, title: '97o'}, {id: 97, title: '87o'},
    {id: 98, title: '77'}, {id: 99, title: '76s'}, {id: 100, title: '75s'}, {id: 101, title: '74s'},
    {id: 102, title: '73s'}, {id: 103, title: '72s'}, {id: 104, title: 'A6o'}, {id: 105, title: 'K6o'},
    {id: 106, title: 'Q6o'}, {id: 107, title: 'J6o'}, {id: 108, title: 'T6o'}, {id: 109, title: '96o'},
    {id: 110, title: '86o'}, {id: 111, title: '76o'}, {id: 112, title: '66'}, {id: 113, title: '65s'},
    {id: 114, title: '64s'}, {id: 115, title: '63s'}, {id: 116, title: '62s'}, {id: 117, title: 'A5o'},
    {id: 118, title: 'K5o'}, {id: 119, title: 'Q5o'}, {id: 120, title: 'J5o'}, {id: 121, title: 'T5o'},
    {id: 122, title: '95o'}, {id: 123, title: '85o'}, {id: 124, title: '75o'}, {id: 125, title: '65o'},
    {id: 126, title: '55'}, {id: 127, title: '54s'}, {id: 128, title: '53s'}, {id: 129, title: '52s'},
    {id: 130, title: 'A4o'}, {id: 131, title: 'K4o'}, {id: 132, title: 'Q4o'}, {id: 133, title: 'J4o'},
    {id: 134, title: 'T4o'}, {id: 135, title: '94o'}, {id: 136, title: '84o'}, {id: 137, title: '74o'},
    {id: 138, title: '64o'}, {id: 139, title: '54o'}, {id: 140, title: '44'}, {id: 141, title: '43s'},
    {id: 142, title: '42s'}, {id: 143, title: 'A3o'}, {id: 144, title: 'K3o'}, {id: 145, title: 'Q3o'},
    {id: 146, title: 'J3o'}, {id: 147, title: 'T3o'}, {id: 148, title: '93o'}, {id: 149, title: '83o'},
    {id: 150, title: '73o'}, {id: 151, title: '63o'}, {id: 152, title: '53o'}, {id: 153, title: '43o'},
    {id: 154, title: '33'}, {id: 155, title: '32s'}, {id: 156, title: 'A2o'}, {id: 157, title: 'K2o'},
    {id: 158, title: 'Q2o'}, {id: 159, title: 'J2o'}, {id: 160, title: 'T2o'}, {id: 161, title: '92o'},
    {id: 162, title: '82o'}, {id: 163, title: '72o'}, {id: 164, title: '62o'}, {id: 165, title: '52o'},
    {id: 166, title: '42o'}, {id: 167, title: '32o'}, {id: 168, title: '22'}
  ];

  emptyChart = ['#0', '#1', '#2', '#3', '#4', '#5', '#6', '#7', '#8', '#9', '#10', '#11', '#12', '#13', '#14', '#15',
    '#16', '#17', '#18', '#19', '#20', '#21', '#22', '#23', '#24', '#25', '#26', '#27', '#28', '#29', '#30', '#31',
    '#32', '#33', '#34', '#35', '#36', '#37', '#38', '#39', '#40', '#41', '#42', '#43', '#44', '#45', '#46', '#47',
    '#48', '#49', '#50', '#51', '#52', '#53', '#54', '#55', '#56', '#57', '#58', '#59', '#60', '#61', '#62', '#63',
    '#64', '#65', '#66', '#67', '#68', '#69', '#70', '#71', '#72', '#73', '#74', '#75', '#76', '#77', '#78', '#79',
    '#80', '#81', '#82', '#83', '#84', '#85', '#86', '#87', '#88', '#89', '#90', '#91', '#92', '#93', '#94', '#95',
    '#96', '#97', '#98', '#99', '#100', '#101', '#102', '#103', '#104', '#105', '#106', '#107', '#108', '#109',
    '#110', '#111', '#112', '#113', '#114', '#115', '#116', '#117', '#118', '#119', '#120', '#121', '#122', '#123',
    '#124', '#125', '#126', '#127', '#128', '#129', '#130', '#131', '#132', '#133', '#134', '#135', '#136', '#137',
    '#138', '#139', '#140', '#141', '#142', '#143', '#144', '#145', '#146', '#147', '#148', '#149', '#150', '#151',
    '#152', '#153', '#154', '#155', '#156', '#157', '#158', '#159', '#160', '#161', '#162', '#163', '#164', '#165',
    '#166', '#167', '#168'];

  defaultChartUuid = '20823dc9-56c9-4918-b7b9-dd03fbdc9b20';

  chartPositions: ChartPosition[] = [
    {id: 0, title: 'SB'},
    {id: 1, title: 'BB'},
    {id: 2, title: 'BTN'},
  ];

  chartGameTypes: ChartGameType[] = [
    {id: 1, title: 'HU', playersCount: 2},
    {id: 2, title: '3-max', playersCount: 3},
  ];

  chartActions: ChartAction[] = [
    {id: 0, title: 'vs btn limp'},
    {id: 1, title: 'vs btn mr'},
    {id: 2, title: 'vs btn os'},
    {id: 3, title: 'vs bb'},
    {id: 0, title: 'vs btn limp'},
    {id: 1, title: 'vs btn mr'},
    {id: 2, title: 'vs btn os'},
    {id: 4, title: 'vs 3w limp'},
    {id: 5, title: 'vs mr (3w)'},
    {id: 6, title: 'vs sb limp'},
    {id: 7, title: 'vs sb mr'},
    {id: 8, title: 'vs sb os'},
    {id: 9, title: 'vs limp'},
    {id: 10, title: 'vs mr'},
    {id: 11, title: 'vs os'},
    {id: 12, title: 'vs sb 3x'},
    {id: 13, title: 'vs 3x'},
    {id: 14, title: 'vs btn 3x'},
    {id: 16, title: 'vs mr + all-in'},
    {id: 17, title: 'vs os + all-in'},
    {id: 18, title: 'vs os + call'},
    {id: 19, title: 'vs sb x2.5'},
    {id: 20, title: 'vs btn x2.5'},
    {id: 21, title: 'vs x2.5'},
  ];

  chartAnswers: Answer[] = [
    {id: 0, title: 'Call', color: {key: 'call', value: '#FFB74D'}, isRandom: 'n', value: 'c'},
    {id: 1, title: 'Raise', color: {key: 'raise', value: '#7986CB'}, isRandom: 'n', value: 'r'},
    {id: 2, title: 'All-in', color: {key: 'all-in', value: '#4CAF50'}, isRandom: 'n', value: 'a'},
    {id: 3, title: 'Fold', color: {key: 'fold', value: '#E0E0E0'}, isRandom: 'n', value: 'f'},
    {id: 4, title: 'Check', color: {key: 'check', value: '#FFB74D'}, isRandom: 'n', value: 'ch'},
  ];

  playerActions: PlayerAction[] = [
    {id: 0, title: 'called'},
    {id: 1, title: 'raised'},
    {id: 2, title: 'all-in'},
    {id: 3, title: 'folded'},
    {id: 4, title: 'checked'},
    {id: 5, title: 'small blind'},
    {id: 6, title: 'big blind'},
  ];

  opponentsTypes: OpponentType[] = [
    {id: 0, type: 'fish'},
    {id: 1, type: 'reg'},
    {id: 2, type: 'fish + reg'},
    {id: 3, type: 'fish + fish'},
    {id: 4, type: 'reg + reg'},
    {id: 5, type: 'reg + fish'}
  ];

  chips: Chips[] = [
    {nominal: 500, color: '#9C27B0', borderColor: '#6A0080'},
    {nominal: 100, color: '#37474F', borderColor: '#102027'},
    {nominal: 25, color: '#2E7D32', borderColor: '#005005'},
    {nominal: 5, color: '#EF5350', borderColor: '#B61827'},
    {nominal: 1, color: '#3F51B5', borderColor: '#002984'},
  ];

  stackViews: StackView[] = [
    {id: 0, title: 'USD', symbol: '$'},
    {id: 1, title: 'EUR', symbol: '€'},
    {id: 2, title: 'GBP', symbol: '£'},
    {id: 3, title: 'BB', symbol: ''}
  ];

  chartColors: ChartColor[] = [
    {key: 'call', value: ActionColors.CALL},
    {key: 'raise', value: ActionColors.RAISE},
    {key: 'raise2', value: ActionColors.RAISE2},
    {key: 'raise3', value: ActionColors.RAISE3},
    {key: 'all-in', value: ActionColors.ALLIN},
    {key: 'fold', value: ActionColors.FOLD},
    {key: 'check', value: ActionColors.CHECK},
  ];

  hotKeys: UserHotKey[] = [
    {title: 'Call / Check', action: 'call', color: ActionColors.CALL},
    {title: 'Raise 1', action: 'raise1', color: ActionColors.RAISE},
    {title: 'Raise 2', action: 'raise2', color: ActionColors.RAISE2},
    {title: 'Raise 3', action: 'raise3', color: ActionColors.RAISE3},
    {title: 'All-in', action: 'all-in', color: ActionColors.ALLIN},
    {title: 'Fold', action: 'fold', color: ActionColors.FOLD},
    {title: 'Close Hint', action: 'closeHint', color: ''},
  ];

  raiseColors: ChartColor[] = [
    {key: 'raise', value: ActionColors.RAISE},
    {key: 'raise2', value: ActionColors.RAISE2},
    {key: 'raise3', value: ActionColors.RAISE3}
  ];

  defaultRandomRanges: string[] = [
    '26',
    '51',
    '125',
    '150'
  ];

  randomRanges: RandomRange[] = [
    {title: '26', rangeNumber: 26},
    {title: '51', rangeNumber: 51},
    {title: '125', rangeNumber: 125},
    {title: '150', rangeNumber: 150},
  ];

  stackSizes: StackSize[] = [
    {id: 0, title: 'all'},
    {id: 1, title: 'short'},
    {id: 2, title: 'medium'},
    {id: 3, title: 'deep'}
  ];

  gameModes: GameMode[] = [
    {id: 0, title: 'none'},
    {id: 1, title: 'easy'},
    {id: 2, title: 'normal'},
    {id: 3, title: 'hard'}
  ];

  stackViewsForSetting: StackViewSetting[] = [
    { id: 1, title: 'chips'},
    { id: 2, title: 'BB'}
  ];

  currencies: Currency[] = [
    { id: 1, title: '$', value: 0},
    { id: 2, title: '€', value: 1},
    { id: 3, title: '£', value: 2}
  ];

  amountViews: AmountView[] = [
    { id: 2, title: '2.7BB', value: 1},
    { id: 3, title: '2.75BB', value: 2}
  ];

  paymentMethods: PaymentMethod[] = [
    {title: 'FAST_SPRING', id: 0},
    {title: 'GIPSY', id: 1},
    {title: 'PS', id: 2},
    {title: '888', id: 3},
    {title: 'NETELLER', id: 4},
    {title: 'WMZ', id: 5},
    {title: 'BTC', id: 6},
    {title: 'SKRILL', id: 7},
    {title: 'POKERENERGY', id: 8},
    {title: 'FREE', id: 9},
    {title: 'SCHOOL', id: 10},
    {title: 'SKRILL €', id: 11},
    {title: 'Pekar Stas', id: 12},
    {title: 'School Promocode', id: 13},
    {title: 'PayPal', id: 14},
  ];

  products: Product[] = [
    {id: 1, title: 'ph-sub-1'},
    {id: 2, title: 'ph-sub-3'},
    {id: 3, title: 'ph-sub-12'},
    {id: 4, title: 'ph-chart-1'},
    {id: 7, title: 'ph-chart-3'},
    {id: 10, title: 'asymmetric-pack'},
    {id: 13, title: 'nitro-pack'},
    {id: 14, title: 'nash-pack'},
    {id: 15, title: 'ph-sub-14-days'},
    {id: 17, title: 'pokerhero-flash'},
    {id: 18, title: 'pokerhero-spin'},
    {id: 9, title: 'bitb-school-new'},
    {id: 16, title: 'hs-school'},
    {id: 12, title: 'firestorm-school'},
    {id: 19, title: 'pokerhero-trial'},
    {id: 20, title: 'flow-1'},

    {id: 21, title: 'lf-upto-5'},
    {id: 22, title: 'lf-upto-10'},
    {id: 23, title: 'lf-upto-25'},
    {id: 24, title: 'lf-upto-50'},
    {id: 25, title: 'lf-upto-100'},
    {id: 26, title: 'lf-upgrade-5'},
    {id: 27, title: 'lf-upgrade-10'},
    {id: 28, title: 'lf-upgrade-25'},
    {id: 29, title: 'lf-upgrade-50'},
    {id: 30, title: 'lf-upgrade-100'},
    {id: 31, title: 'lf-upto-5-3'},
    {id: 31, title: 'lf-upto-10-3'},
    {id: 31, title: 'lf-upto-25-3'},
    {id: 31, title: 'lf-upto-50-3'},
    {id: 31, title: 'lf-upto-100-3'},
    {id: 31, title: 'lf-upto-5-12'},
    {id: 31, title: 'lf-upto-10-12'},
    {id: 31, title: 'lf-upto-25-12'},
    {id: 31, title: 'lf-upto-50-12'},
    {id: 31, title: 'lf-upto-100-12'},
  ];

  constructor() {
  }

  getPaymentMethodById(id: number): PaymentMethod {
    return _.cloneDeep(this.paymentMethods.find((paymentMethod: PaymentMethod) => paymentMethod.id === id));
  }

  getProductByTitle(title: string): Product {
    return _.cloneDeep(this.products.find((product: Product) => product.title === title));
  }

  getChartPositionById(id: number): ChartPosition {
    return _.cloneDeep(this.chartPositions.find((position: ChartPosition) => position.id === id));
  }

  getChartGameTypeById(playersCount: number): ChartGameType {
    return _.cloneDeep(this.chartGameTypes.find(
      (gameType: ChartGameType) => gameType.playersCount === playersCount));
  }

  getChartActionById(id: number): ChartAction {
    return _.cloneDeep(this.chartActions.find((action: ChartAction) => action.id === id));
  }

  getChartAnswerById(id: number): Answer {
    return _.cloneDeep(this.chartAnswers.find((answer: Answer) => answer.id === id));
  }

  getPlayerActionById(id: number): PlayerAction {
    return _.cloneDeep(this.playerActions.find((action: PlayerAction) => action.id === id));
  }

  getOpponentsTypeById(id: number): OpponentType {
    return _.cloneDeep(this.opponentsTypes.find((type: OpponentType) => type.id === id));
  }

  getSTackViewById(id: number): StackView {
    return _.cloneDeep(this.stackViews.find((stackView: StackView) => stackView.id === id));
  }

  getDefaultCombinations(): Combination[] {
    return _.cloneDeep(this.defaultCombinations);
  }

  isStackInBB(stack: StackView): boolean {
    return stack.id === 3;
  }

  getDefaultChips(): Chips[] {
    return _.cloneDeep(this.chips);
  }

  getDefaultRandomRanges(): string[] {
    return _.cloneDeep(this.defaultRandomRanges);
  }

  getCombinationByTitle(title: string, title2: string): Combination {

    const foundCombination = this.getDefaultCombinations().find((combination: Combination) => combination.title === title);
    if (foundCombination) {
      return foundCombination;
    } else {
      return this.getDefaultCombinations().find((combination: Combination) => combination.title === title2);
    }
  }

  getRaisedColors(): ChartColor[] {
    return this.raiseColors;
  }

  getColorByKey(key: string): ChartColor {
    let color: ChartColor;

    switch (key) {

      case 'orange':
        color = this.getColorByKey('call');
        break;

      case 'purple':
        color = this.getColorByKey('raise');
        break;

      case 'green':
        color = this.getColorByKey('all-in');
        break;

      case 'gray':
        color = this.getColorByKey('fold');
        break;

      case 'teal':
        color = this.getColorByKey('raise2');
        break;

      case 'blue':
        color = this.getColorByKey('raise3');
        break;

      case 'gold':
        color = this.getColorByKey('check');
        break;

      default:
        color = _.cloneDeep(this.chartColors.find((chartColor: ChartColor) => chartColor.key === key));
    }

    return color;
  }

  getRandomRangesByRandomNumber(randomValue: number): RandomRange[] {
    return this.randomRanges.filter((range: RandomRange) => range.rangeNumber === randomValue);
  }

  getCurrencies(): Currency[] {
    return this.currencies;
  }

  getStackViewsForSetting(): StackViewSetting[] {
    return this.stackViewsForSetting;
  }

  getStackSizes(): StackSize[] {
    return this.stackSizes;
  }

  getGameModes(): GameMode[] {
    return this.gameModes;
  }

  getOpponentsTypes(): OpponentType[] {
    return this.opponentsTypes;
  }

  getStackSizeById(id: number): StackSize {
    return _.cloneDeep(this.stackSizes.find((stackSize: StackSize) => stackSize.id === id));
  }

  getHotKeyByAction(action: string): UserHotKey {
    return _.cloneDeep(this.hotKeys.find((hotKey: UserHotKey) => hotKey.action === action));
  }

  getAmountViews(): AmountView[] {
    return this.amountViews;
  }

  getPaymentMethods(): PaymentMethod[] {
    return this.paymentMethods;
  }

  getProducts(): Product[] {
    return this.products;
  }

  getSfPackUuid(): string {
    return 'c7804228-b578-4744-99b3-55ae9b8c5786';
  }

  getSpCoursePackUuid(): string {
    return '573eadc6-77d2-48a7-b4fe-e792e0217ecb';
  }

  getSpPackUuid(): string {
    return '1a7b25ec-d1c1-4708-a5f4-c9cea850dead';
  }

  getGtoPackUuid(): string {
    return '8bf4e61c-6e22-443a-8539-f0a2ca27e041';
  }

  getCombinationTitleById(id: number) {
    return this.defaultCombinations.find((combination: Combination) => combination.id === id).title;
  }

  getColorByCombinationSymbol(symbol: string): string {
    switch (symbol) {
      case 'c':
        return ActionColors.CALL;
      case 'r':
        return ActionColors.RAISE;
      case 'r2':
        return ActionColors.RAISE2;
      case 'r3':
        return ActionColors.RAISE3;
      case 'f':
        return ActionColors.FOLD;
      case 'a':
        return ActionColors.ALLIN;
      case 'ch':
        return ActionColors.CHECK;
    }
  }

  getSymbolByActionId(id: number): string {
    switch (id) {
      case 0:
        return 'c';
      case 1:
        return 'r';
      case 2:
        return 'a';
      case 3:
        return 'f';
      case 4:
        return 'ch';
    }
  }

  getEmptyChart(): string[] {
    return _.cloneDeep(this.emptyChart);
  }

  getSecondActionTitleById(id: number): string {
    switch (id) {
      case 0:
        return 'all-in';
      case 1:
        return '3-bet';
      case 2:
        return 'iso';
      case 3:
        return 'iso all-in';
    }
  }

}

export enum ActionColors {
  CALL = '#FFB74D',
  RAISE = '#7986CB',
  RAISE2 = '#8D6E63',
  RAISE3 = '#EF5350',
  ALLIN = '#4CAF50',
  FOLD = '#E0E0E0',
  CHECK = '#FFB74D'
}

