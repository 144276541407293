import { Injectable } from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class IconService {

  constructor(private iconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {
  }

  initializeCardsIcons() {

    // fish
    this.iconRegistry.addSvgIcon(
      'fish',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/fish.svg'));

    // reg
    this.iconRegistry.addSvgIcon(
      'reg',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/shark.svg'));

    // hero
    this.iconRegistry.addSvgIcon(
      'hero',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hero.svg'));

    // random
    this.iconRegistry.addSvgIcon(
      'random',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/random.svg'));

    // skype
    this.iconRegistry.addSvgIcon(
      'skype',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/skype.svg'));

    // cup
    this.iconRegistry.addSvgIcon(
      'cup',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/cup.svg'));

    // diamond
    this.iconRegistry.addSvgIcon(
      'diamond',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/diamond.svg'));

    // hammer
    this.iconRegistry.addSvgIcon(
      'hammer',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hammer.svg'));

    // list
    this.iconRegistry.addSvgIcon(
      'list',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/list.svg'));

    // swords
    this.iconRegistry.addSvgIcon(
      'swords',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/swords.svg'));

    // emoji_events
    this.iconRegistry.addSvgIcon(
      'emoji_events',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/emoji_events.svg'));

    // clubs
    this.iconRegistry.addSvgIcon(
      'clubs',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/clubs.svg'));

    // hearts
    this.iconRegistry.addSvgIcon(
      'hearts',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hearts.svg'));

    // diams
    this.iconRegistry.addSvgIcon(
      'diams',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/diams.svg'));

    // spades
    this.iconRegistry.addSvgIcon(
      'spades',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/spades.svg'));

    // drag_indicator
    this.iconRegistry.addSvgIcon(
      'drag_indicator',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/drag_indicator.svg'));

    this.iconRegistry.addSvgIcon(
      'rocket',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/rocket.svg'));

    this.iconRegistry.addSvgIcon(
      'gglogo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/gglogo.svg'));

    this.iconRegistry.addSvgIcon(
      'winlogo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/winlogo.svg'));

    this.iconRegistry.addSvgIcon(
      'pslogo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/pslogo.svg'));

    this.iconRegistry.addSvgIcon(
      'redstarlogo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/redstarlogo.svg'));

    this.iconRegistry.addSvgIcon(
      'partylogo',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/partylogo.svg'));

    this.iconRegistry.addSvgIcon(
      'hands',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/hands.svg'));

    this.iconRegistry.addSvgIcon(
      'mistake',
      this.sanitizer.bypassSecurityTrustResourceUrl('assets/icons/mistake.svg'));

  }
}
