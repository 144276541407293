import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Metadata} from '../models/metadata';
import {Observable, of} from 'rxjs';
import {pluck, switchMap} from 'rxjs/operators';
import {Transaction} from '../models/transaction';


@Injectable({
  providedIn: 'root'
})
export class ClubService {

  backendUrl = '/avatar';
  polygonApiUrl = '/club';
  polygonApiKey = 'NU2JI5CH11R6B45F519XMU12TIJGU9AHZM';
  contractAddress = '0x512dd59f2d6be7986d320a03071b67bea80606aa';

  constructor(private httpClient: HttpClient) {
  }

  metadataById(id: number): Observable<Metadata> {
    return this.httpClient.get( `${this.backendUrl}/metadata/${id}`) as Observable<Metadata>;
  }

  findUserNft(address: string): Observable<Array<number>> {
    return this.httpClient.get<{result: Transaction[]}>(
      `${this.polygonApiUrl}api?module=account&action=tokennfttx&contractaddress=${this.contractAddress}&address=${address}&apikey=${this.polygonApiKey}`).pipe(
      pluck('result'),
      switchMap((transactions: Transaction[]) => {
        const tokenIds = [];
        for (const transaction of transactions) {
          if (transaction.to === address) {
            tokenIds.push(+transaction.tokenID);
          } else {
            const index = tokenIds.findIndex(v => v === +transaction.tokenID);
            if (index !== -1) {
              tokenIds.splice(index, 1);
            }
          }
        }
        return of(tokenIds);
      })
    );
  }


}
