import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ClubService} from '../../services/club.service';
import {Metadata} from "../../models/metadata";
declare let Web3: any;
declare let window: any;

@Component({
  selector: 'app-club-connection',
  templateUrl: './club-connection.component.html',
  styleUrls: ['./club-connection.component.scss']
})
export class ClubConnectionComponent implements OnInit {

  @Output() availableAvatars: EventEmitter<Array<Metadata>> = new EventEmitter();
  avatarsMeta: Array<Metadata> = [];

  Web3Provider = {
    mumbai: 'https://rpc-mumbai.maticvigil.com',
    mainnet: 'https://rpc-mainnet.maticvigil.com'
  };

  tokenIds: Array<number | string> = [];

  userAddress = '';
  userBalance: string | number = '';
  userBalanceOnWallet: string | number = '';

  // change to main net
  web3 = new Web3(this.Web3Provider.mainnet);
  // change to '0x89'
  chainId = '0x89';
  contractAddress = '0x512dd59f2d6be7986d320a03071b67bea80606aa';
  coinContractAddress = '0x26a04de051abed84850f9b6b7ba2400d2c4ca26c';


  isConnected = false;
  isCorrectNetwork = false;
  isWatcherOn = false;
  tokenCount = 0;


  constructor(private clubService: ClubService) {
  }

  ngOnInit() {
  }

  async init() {
    if (!this.isConnected) {
      await this.connect();
      this.isConnected = window.ethereum.isConnected;
    }

    if (this.isConnected) {

      this.isCorrectNetwork = this.checkChain();
      if (!this.isWatcherOn) {
        this.turnChainWatcher();
        this.isWatcherOn = true;
      }

      const length = window.ethereum.selectedAddress.length;
      this.userAddress = `${window.ethereum.selectedAddress.substr(
        0, 2)}...${window.ethereum.selectedAddress.substr(length - 4, length - 1)}`;

    }

    if (this.isConnected && !this.isCorrectNetwork) {
      // handle wrong network
    }

    if (this.isConnected && this.isCorrectNetwork) {
      await this.fetchUserTokens();
    }
  }

  turnChainWatcher() {
    window.ethereum.on('chainChanged', () => {
      this.checkChain();
      this.init();
    });
  }

  async connect() {
    await window.ethereum.request({method: 'eth_requestAccounts'});
  }

  checkChain() {
    return window.ethereum.chainId === this.chainId;
  }

  async fetchUserTokens() {
    this.tokenCount = await this.balanceOf();

    if (this.tokenCount === 0) {
      // handle situation when no nft
    } else {
      this.clubService.findUserNft(window.ethereum.selectedAddress).subscribe(
        tokenIds => {
          for (const tokenId of tokenIds) {
            this.clubService.metadataById(tokenId).subscribe(v => {
              this.avatarsMeta.push(v);
              this.availableAvatars.emit(this.avatarsMeta);
            });
          }
        });
    }
  }

  async balanceOf() {
    const abi: any = [{
      constant: true,
      inputs: [{"name": "_owner", "type": "address"}],
      name: "balanceOf",
      outputs: [{"name": "balance", "type": "uint256"}],
      type: "function"
    }];

    const contract = await new this.web3.eth.Contract(abi, this.contractAddress);

    const addressHex = this.web3.utils.numberToHex(window.ethereum.selectedAddress);

    return await contract.methods.balanceOf(addressHex).call();
  }

}
