import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { Player } from '../../../models/player';
import { ViewInfo } from '../../../models/user/view-info';
import { UserService } from '../../../services/user.service';

@Component({
  selector: 'app-wm-player-hud-hero',
  templateUrl: './wm-player-hud-hero.component.html',
  styleUrls: ['./wm-player-hud-hero.component.scss']
})
export class WmPlayerHudHeroComponent implements OnInit {
  @HostBinding('class.app-wm-player-hud-hero')
  public readonly hasHostClassName: boolean = true;

  @Input() player: Player;
  @Input() viewInfo: ViewInfo;

  avatarId: number;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.avatarId = this.userService.getUser().settings.avatar;
  }

}
