import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'chapterName'
})
export class ChapterNamePipe implements PipeTransform {

  transform(name: string): string {
    let newName = name.replace('[Easy]', '');
    newName = newName.replace('[Normal]', '');
    newName = newName.replace('[Hard]', '');
    return newName.trim();
  }
}
