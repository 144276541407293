import {Component, Input, OnInit, TemplateRef, ViewEncapsulation} from '@angular/core';
import {AppService} from '../../services/app.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
  // changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false
})
export class HeaderComponent implements OnInit {


  get titleTemplate(): TemplateRef<any> {

    return this.appService.headerTitleTemplate;
  }

  get actionsTemplate(): TemplateRef<any> {

      return this.appService.headerActionsTemplate;
  }

  get isAlignStart(): boolean {

    return this.appService.isAlignStart;
  }

  @Input() mobileMenu: boolean;

  constructor(private appService: AppService) {
  }

  ngOnInit() {
  }

  toggleMenu() {
    this.appService.toggleMenu();
  }
}
