import {Component, Input, OnInit} from '@angular/core';
import {Player} from '../../../models/player';
import { UserService } from 'src/app/services/user.service';
import {ViewInfo} from '../../../models/user/view-info';

@Component({
  selector: 'app-player-hud',
  templateUrl: './player-hud.component.html',
  styleUrls: ['./player-hud.component.scss']
})
export class PlayerHudComponent implements OnInit {

  @Input() player: Player;
  @Input() viewInfo: ViewInfo;
  @Input() reverse: boolean;

  layoutCard: string;
  avatarId: number;

  constructor(private userService: UserService) { }

  ngOnInit() {
    this.layoutCard = localStorage.getItem('layoutCard');
    this.avatarId = this.userService.getUser().settings.avatar;
  }

}
