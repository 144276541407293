// angular
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {ReactiveFormsModule} from '@angular/forms';
import {FormsModule} from '@angular/forms';
// material
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatSelectModule} from '@angular/material/select';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDialogModule} from '@angular/material/dialog';
import {MatInputModule} from '@angular/material/input';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatListModule} from '@angular/material/list';
import {MatDividerModule} from '@angular/material/divider';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressBarModule, MatNativeDateModule} from '@angular/material';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatMenuModule} from '@angular/material/menu';
import {MatRadioModule} from '@angular/material/radio';
// components
import { ChartComponent } from './components/chart/chart.component';
import { ChartCellComponent } from './components/chart-cell/chart-cell.component';
import { StaticChartComponent } from './components/static-chart/static-chart.component';
import { ChipsComponent } from './components/chips/chips.component';
import { PokerTableComponent } from './components/poker-table/poker-table.component';
import { PlayerHudComponent } from './components/player-hud/player-hud.component';
import { StatsTableComponent } from './components/stats-table/stats-table.component';
import { RadioButtonComponent } from './components/radio-button/radio-button.component';
import { PercentBarComponent } from './components/percent-bar/percent-bar.component';
import { UsersTableComponent } from './components/users-table/users-table.component';
import { PurchasesTableComponent } from './components/purchases-table/purchases-table.component';
import {ActionButtonComponent} from './components/action-button/action-button.component';
import {ActionAlternativeButtonComponent} from './components/action-alternative-button/action-alternative-button.component';
import {HandsRangeComponent} from './components/hands-range/hands-range.component';
import {HandsFilterDialogComponent} from './modals/hands-filter-dialog/hands-filter-dialog.component';
import {DemoPokerTableComponent} from './components/demo-poker-table/demo-poker-table.component';
import { StudentTableComponent } from './components/student-table/student-table.component';
import { WmPokerTableComponent } from './components/wm-poker-table/wm-poker-table.component';
import { WmPlayerHudHeroComponent } from './components/wm-player-hud-hero/wm-player-hud-hero.component';
import { WmPlayerHudBotComponent } from './components/wm-player-hud-bot/wm-player-hud-bot.component';
// modals
import { HandReplayDialogComponent } from './modals/hand-replay-dialog/hand-replay-dialog.component';
import { AnswerCreatingDialogComponent } from './modals/answer-creating-dialog/answer-creating-dialog.component';
import { BuyDialogComponent } from './modals/buy-dialog/buy-dialog.component';
import { StaticRangeViewComponent } from './modals/static-range-view/static-range-view.component';
import { ConfirmDialogComponent } from './modals/confirm-dialog/confirm-dialog.component';
import { SplitAnswerCreatingDialogComponent } from './modals/split-answer-creating-dialog/split-answer-creating-dialog.component';
import { GameModeHandsRangeDialogComponent } from './modals/game-mode-hands-range-dialog/game-mode-hands-range-dialog.component';
import { ImportDialogComponent } from './modals/import-dialog/import-dialog.component';
import { FinishLessonDialogComponent } from './modals/finish-lesson-dialog/finish-lesson-dialog.component';
// pipes
import { StackToBbPipe } from './pipes/stack-to-bb.pipe';
import { ActionViewPipe } from './pipes/action-view.pipe';
import { OppsTitlePipe } from './pipes/opps-title.pipe';
import { ProductNamePipe } from './pipes/product-name.pipe';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import {RouterModule} from '@angular/router';
import { TimeToDaysPipe } from './pipes/time-to-days.pipe';
import {ChapterNamePipe} from './pipes/chapter-name.pipe';
import {ClubModule} from '../modules/club/club.module';
import { SimpleMistakesChartComponent } from './components/simple-mistakes-chart/simple-mistakes-chart.component';
import { ViewerComponent } from './components/viewer/viewer.component';
import { MatSliderModule } from '@angular/material/slider';
import { WmChipsComponent } from './components/wm-chips/wm-chips.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    ChartComponent,
    ChartCellComponent,
    AnswerCreatingDialogComponent,
    StaticChartComponent,
    ChipsComponent,
    StackToBbPipe,
    ActionViewPipe,
    OppsTitlePipe,
    TimeToDaysPipe,
    RadioButtonComponent,
    PercentBarComponent,
    StatsTableComponent,
    BuyDialogComponent,
    PokerTableComponent,
    DemoPokerTableComponent,
    PlayerHudComponent,
    HandReplayDialogComponent,
    StaticRangeViewComponent,
    UsersTableComponent,
    PurchasesTableComponent,
    ProductNamePipe,
    ChapterNamePipe,
    ConfirmDialogComponent,
    ActionButtonComponent,
    ActionAlternativeButtonComponent,
    BreadcrumbComponent,
    HandsRangeComponent,
    HandsFilterDialogComponent,
    StudentTableComponent,
    SplitAnswerCreatingDialogComponent,
    GameModeHandsRangeDialogComponent,
    ImportDialogComponent,
    FinishLessonDialogComponent,
    SimpleMistakesChartComponent,
    ViewerComponent,
    WmPokerTableComponent,
    WmPlayerHudHeroComponent,
    WmPlayerHudBotComponent,
    WmChipsComponent,
  ],
  entryComponents: [
    AnswerCreatingDialogComponent,
    BuyDialogComponent,
    HandReplayDialogComponent,
    StaticRangeViewComponent,
    ConfirmDialogComponent,
    HandsFilterDialogComponent,
    SplitAnswerCreatingDialogComponent,
    GameModeHandsRangeDialogComponent,
    ImportDialogComponent,
    FinishLessonDialogComponent
  ],
  imports: [
    RouterModule,
    FormsModule,
    CommonModule,
    MatIconModule,
    MatButtonModule,
    MatCardModule,
    MatTabsModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatSelectModule,
    MatInputModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatDividerModule,
    MatExpansionModule,
    MatTooltipModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatCheckboxModule,
    MatMenuModule,
    MatRadioModule,
    MatSliderModule,
    ClubModule,
    TranslateModule.forChild({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    })
  ],
    exports: [
        ReactiveFormsModule,
        FormsModule,
        TranslateModule,
        // material
        MatIconModule,
        MatButtonModule,
        MatCardModule,
        MatSelectModule,
        MatTabsModule,
        MatDialogModule,
        MatInputModule,
        MatSnackBarModule,
        MatListModule,
        MatDividerModule,
        MatSnackBarModule,
        MatSidenavModule,
        MatToolbarModule,
        MatExpansionModule,
        MatTooltipModule,
        MatProgressBarModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatMenuModule,
        MatRadioModule,
        // components
        ChartComponent,
        ChartCellComponent,
        StaticChartComponent,
        RadioButtonComponent,
        ChipsComponent,
        PercentBarComponent,
        StatsTableComponent,
        BuyDialogComponent,
        PokerTableComponent,
        WmPokerTableComponent,
        WmPlayerHudHeroComponent,
        WmPlayerHudBotComponent,
        WmChipsComponent,
        DemoPokerTableComponent,
        PlayerHudComponent,
        HandReplayDialogComponent,
        StaticRangeViewComponent,
        UsersTableComponent,
        ActionButtonComponent,
        ActionAlternativeButtonComponent,
        BreadcrumbComponent,
        HandsRangeComponent,
        HandsFilterDialogComponent,
        StudentTableComponent,
        GameModeHandsRangeDialogComponent,
        SimpleMistakesChartComponent,
        ViewerComponent,
        // pipes
        StackToBbPipe,
        ActionViewPipe,
        OppsTitlePipe,
        PurchasesTableComponent,
        ProductNamePipe,
        TimeToDaysPipe,
        ChapterNamePipe
    ]
})
export class SharedModule { }
