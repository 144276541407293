import {Component, Input, OnInit} from '@angular/core';
import {TaskAnswer} from '../../../models/task-answer';
import {Task} from '../../../models/task';
import {ViewInfo} from '../../../models/user/view-info';

@Component({
  selector: 'app-action-alternative-button',
  templateUrl: './action-alternative-button.component.html',
  styleUrls: ['./action-alternative-button.component.scss']
})
export class ActionAlternativeButtonComponent implements OnInit {

  @Input() action: TaskAnswer;
  @Input() currentTask: Task;
  @Input() viewInfo: ViewInfo;

  constructor() { }

  ngOnInit() {
  }

}
