import {Injectable} from '@angular/core';
import {ApiService} from '../services/api.service';
import {Observable, of} from 'rxjs';
import {tap} from 'rxjs/operators';
import {StaticRange} from '../models/chart/static-range';

@Injectable({
  providedIn: 'root'
})
export class ChartStorage {

  charts: StaticRange[] = [];
  asymmetricCharts: StaticRange[] = [];
  charts3bet: StaticRange[] = [];
  chartsAllIn: StaticRange[] = [];
  chartsIso: StaticRange[] = [];
  chartsIsoPush: StaticRange[] = [];

  constructor(private apiService: ApiService) { }

  private findInStorage(rangeId: number, secondAction: number, secondActionFactor: number, isAsymmetric?: boolean): StaticRange {
    if (isAsymmetric) {
      return this.asymmetricCharts.find((chart: StaticRange) => chart.id === rangeId);
    }

    switch (secondAction) {
      case 0:
        return this.chartsAllIn.find((chart: StaticRange) => chart.id === rangeId);
      case 1:
        return this.charts3bet.find((chart: StaticRange) => chart.id === rangeId
          && chart.chart.secondActionFactor === secondActionFactor);
      case 2:
        return this.chartsIso.find((chart: StaticRange) => chart.id === rangeId
          && chart.chart.secondActionFactor === secondActionFactor);
      case 3:
        return this.chartsIsoPush.find((chart: StaticRange) => chart.id === rangeId);
      default:
        return this.charts.find((chart: StaticRange) => chart.id === rangeId);
    }
  }

  private writeRange(secondAction: number, range: StaticRange, secondActionFactor: number, isAsymmetric?: boolean) {
    if (isAsymmetric) {
      this.asymmetricCharts.push(range);
      return;
    }

    switch (secondAction) {
      case 1:
        const prefix = secondActionFactor ? ' x' + secondActionFactor.toString() : '';
        range.title += ' vs 3-bet' + prefix;
        this.charts3bet.push(range);
        break;
      case 0:
        range.title += ' vs all-in';
        this.chartsAllIn.push(range);
        break;
      case 2:
        const prefixIso = secondActionFactor ? ' x' + secondActionFactor.toString() : '';
        range.title += ' vs iso' + prefixIso;
        this.chartsIso.push(range);
        break;
      case 3:
        range.title += ' vs iso all-in';
        this.chartsIsoPush.push(range);
        break;
      default:
        this.charts.push(range);
    }
  }

  getRangeById(rangeId: number, secondAction: number, secondActionFactor: number, isAsymmetric = false): Observable<StaticRange> {
    const range = this.findInStorage(rangeId, secondAction, secondActionFactor, isAsymmetric);
    if (range) {
      return of(range);
    } else {
      return this.apiService.getRangeById(rangeId, secondAction, secondActionFactor, isAsymmetric).pipe(
        tap((newRange: StaticRange) => this.writeRange(secondAction, newRange, secondActionFactor))
      );
    }
  }


}
