import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Task} from '../../../models/task';
import {ViewInfo} from '../../../models/user/view-info';
import {Card} from '../../../models/card';
import {BehaviorSubject, defer, interval, Observable} from 'rxjs';
import {filter, map, share, take, withLatestFrom} from 'rxjs/operators';

@Component({
  selector: 'app-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss']
})
export class ViewerComponent implements OnInit, OnDestroy {

  @Input() tasks: Task[];

  postflopTasks;

  public tableMode: string;

  @Input() cards: Card;
  viewInfo: ViewInfo = {isStackInBB: true, bb: 0, roundTo: 2, isEffectiveStackShown: true, symbol: '$'};

  tableIndex = 1;
  turntable = { paused: new BehaviorSubject<boolean>(true), obs: null };

  constructor() {
  }

  onClickPlay() {
    const subject = new BehaviorSubject<boolean>(false);
    this.turntable = { paused: subject, obs: this.getPausableTimer(this.postflopTasks.length, subject) };
  }

  onClickPause() {
    this.turntable.paused.next(true);
  }

  onClickStartOver() {
    this.tableIndex = 1;
    this.onClickPlay();
  }

  getPausableTimer(timeout: number, pause: BehaviorSubject<boolean>): Observable<any> {
    return defer(() => {
      return interval(1000).pipe(
        withLatestFrom(pause),
        filter(([v, paused]) => !paused),
        take(timeout),
        map(() => {
          if (this.tableIndex < timeout) {
            this.tableIndex++;
            this.viewInfo.bb = this.postflopTasks[this.tableIndex - 1].bb;
          }
        })
      );
    }).pipe(share());
  }

  onInputChange(event: any) {
    this.tableIndex = event.value;
    this.viewInfo.bb = this.postflopTasks[this.tableIndex - 1].bb;
    this.turntable.paused.next(true);
  }

  ngOnDestroy() {
  }

  ngOnInit() {
    this.tableMode = localStorage.getItem('layoutCard') ? localStorage.getItem('layoutCard') : 'classic';

    this.postflopTasks = [
    ];
  }

  openPrev() {
    this.tableIndex = this.tableIndex - 1;
    this.viewInfo.bb = this.postflopTasks[this.tableIndex - 1].bb;
    this.turntable.paused.next(true);
  }

  openNext() {
    this.tableIndex = this.tableIndex + 1;
    this.viewInfo.bb = this.postflopTasks[this.tableIndex - 1].bb;
    this.turntable.paused.next(true);
  }

}
