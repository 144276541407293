import {PokerHeroProduct} from './paypal-buy-dialog.model';

export const PRODUCTS: PokerHeroProduct[] = [
  {
    id: 'ph-sub-1',
    title: '1 Month Premium',
    description: '',
    cost: 20,
  },
  {
    id: 'ph-sub-3',
    title: '3 Months Premium',
    description: '',
    cost: 45,
  },
  {
    id: 'ph-sub-12',
    title: '12 Months Premium',
    description: '',
    cost: 150,
  },
  {
    id: 'asymmetric-pack',
    title: 'Asymmetric pack',
    description: '',
    cost: 39,
  },
  {
    id: 'ph-chart-1',
    title: 'SawCrew\'s pack',
    description: '',
    cost: 99,
  },
  {
    id: 'ph-chart-2',
    title: 'Steel Phoenix chart',
    description: '',
    cost: 119,
  },
  {
    id: 'sf-ns-course',
    title: 'Steel Phoenix course',
    description: '',
    cost: 159,
  },
  {
    id: 'ph-chart-3',
    title: 'GTO pack',
    description: '',
    cost: 199,
  },
  {
    id: 'nitro-pack',
    title: 'Nitro pack',
    description: '',
    cost: 149,
  },
  {
    id: 'nash-pack',
    title: 'Nash pack',
    description: '',
    cost: 29,
  },
  // leak finder
  {
    id: 'lf-upto-5',
    title: 'LeakFinder for limit up to 5',
    description: '',
    cost: 40,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upto-10',
    title: 'LeakFinder for limit up to 10',
    description: '',
    cost: 50,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upto-25',
    title: 'LeakFinder for limit up to 25',
    description: '',
    cost: 75,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upto-50',
    title: 'LeakFinder for limit up to 50',
    description: '',
    cost: 100,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upto-100',
    title: 'LeakFinder for all limits',
    description: '',
    cost: 150,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upgrade-5',
    title: 'Upgrade upto 5 limit',
    description: '',
    cost: 40,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upgrade-10',
    title: 'Upgrade upto 10 limit',
    description: '',
    cost: 50,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upgrade-25',
    title: 'Upgrade upto 25 limit',
    description: '',
    cost: 75,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upgrade-50',
    title: 'Upgrade upto 50 limit',
    description: '',
    cost: 100,
    navigateTo: 'leak-finder',
  },
  {
    id: 'lf-upgrade-100',
    title: 'Upgrade for all limits',
    description: '',
    cost: 150,
    navigateTo: 'leak-finder',
  },
];
