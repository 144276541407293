import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AvatarPickerComponent } from './components/avatar-picker/avatar-picker.component';
import { ClubConnectionComponent } from './components/club-connection/club-connection.component';
import {MatMenuModule} from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { AvatarViewerComponent } from './components/avatar-viewer/avatar-viewer.component';

@NgModule({
  declarations: [
    AvatarPickerComponent,
    ClubConnectionComponent,
    AvatarViewerComponent
  ],
  imports: [
    CommonModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule
  ],
  exports: [
    AvatarPickerComponent,
    ClubConnectionComponent,
    AvatarViewerComponent,
  ]
})
export class ClubModule { }
