import {Injectable} from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router
} from '@angular/router';
import {Observable, of} from 'rxjs';
import {AppService} from '../services/app.service';
import {UserService} from '../services/user.service';
import {switchMap} from 'rxjs/operators';
import {User} from '../models/user/user';


@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {

  constructor(private appService: AppService, private userService: UserService,
              private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.appService.isAuthenticated.value) {
      const buy = next.queryParamMap.get('buy');
      if (buy) {
        localStorage.setItem('buy', buy);
      }
      this.router.navigate(['/training']);
      return false;
    }
    const token = localStorage.getItem('token');
    if (token) {
      this.userService.setToken(token);
      return this.userService.checkInvite().pipe(
        switchMap((data: {isExist: boolean, user: User}) => {
          if (data.isExist) {
            const buy = next.queryParamMap.get('buy');
            if (buy) {
              localStorage.setItem('buy', buy);
            }
            this.appService.isAuthenticated.next(true);
            this.router.navigate(['/training']);
            return of(false);
          } else {
            return of(true);
          }
        })
      );
    } else {
      return true;
    }
  }

}
