import {Component, Input} from '@angular/core';
import {Player} from '../../../models/player';
import {ViewInfo} from '../../../models/user/view-info';
import {Card} from '../../../models/card';

@Component({
  selector: 'app-poker-table',
  templateUrl: './poker-table.component.html',
  styleUrls: ['./poker-table.component.scss']
})
export class PokerTableComponent {

  @Input() hero: Player;
  @Input() opponent1: Player;
  @Input() opponent2: Player;
  @Input() pot: number;
  @Input() postFlopPot: number;
  @Input() effectiveStack: number;
  @Input() isEffectiveStackShown: boolean;
  @Input() info: {questions: number, correct: number} = {questions: 0, correct: 0};
  @Input() viewInfo: ViewInfo;
  @Input() cards: Array<Card> = [];
  @Input() mode: string;

  constructor() { }

}
