import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MistakesChartCombination} from '../../../models/stats/mistakes-chart-combination';

@Component({
  selector: 'app-simple-mistakes-chart',
  templateUrl: './simple-mistakes-chart.component.html',
  styleUrls: ['./simple-mistakes-chart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleMistakesChartComponent implements OnInit {

  @Input() combinations: MistakesChartCombination[];

  constructor() { }

  ngOnInit() {
  }

}
